:root {
  --input-bgc                  : #ffffcc;
  --input-underline            : lightgray;
  --green                      : green;
  --yellowgreen                : yellowgreen;
  --summary-title              : var(--green);
  --btn-submit-color           : var(--green);
  --btn-print-color            : var(--yellowgreen);
}

body {
  font-family: Arial;
  color: #454545;
  padding: 1rem;
  margin: 0;
}

h1 { margin-top: 0; }
p { margin: 0; }

.dashboard {
  padding-top: 1rem;
  &-group {
    display: flex;
    flex-wrap: wrap;
  }
  &-title { width: 100%; }
}
.agent {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  column-gap: 1.5rem;
  width: 100%;

  select {
    margin-top: 0.5rem;
    padding: 0.33rem 1.5rem;
    font-size: 1.0375rem;
    border: none;
    background: var(--input-bgc);
    border-bottom: 1px solid var(--input-underline);
  }

  &-buttons {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;

    button {
      width: 200px;
      margin-bottom: 0;
      margin-top: 0.75rem;
      height: auto;
    }
  }

  &-group { margin-bottom: 1rem; }
  &-input {
    margin-top: 0.75rem;
    padding: 0.5rem 1rem;
    background-color: var(--input-bgc);
    border: none;
    border-bottom: 1px solid var(--input-underline);
  }
  &-btn {
    margin-bottom: 1rem;
    padding: 0.5rem 2rem;
    height: 100%;
    border: none;
    border-radius: 1.5rem;
    color: white;
    font-size: 1.15rem;
    cursor: pointer;
  }
  &-btn-submit {
    background-color: var(--btn-submit-color);
  }
  &-btn-print {
    background-color: var(--btn-print-color);
  }

  &-summary {
    width: 50%;
    max-width: 510px;
    &-title {
      font-size: 1.25rem;
      color: var(--summary-title);
      margin: 0;
      padding-bottom: 0.5rem;
    }
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 0.25rem;
      
      div:first-child { width: 250px; }
    }
  }
  &-notice {
    font-style: italic;
    color: #797979;
    align-self: flex-end;
    max-width: 400px;
  }
}

.summary {
  margin-top: 3rem;
  padding-top: 1rem;
  border-top: 1px solid #c1c1c1;
  width: 100vw;

  &-title + &-row {
    padding-bottom: 1rem;
  }
  &-row {
    display: grid;
    grid-template-columns: 50px 100px 100px 150px 250px 120px 100px 95px repeat(2, 100px);
    grid-column-gap: 1rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #efefef;
  }
  .item { word-break: break-all }
  .item:nth-last-child(-n+2),
  .item-head:nth-last-child(-n+2) { text-align: right; }
}

.app-error {
  margin-bottom: 1.5rem;
  background: pink;
  position: absolute;
  top: 1rem;
  right: 1rem;
  text-align: right;
  color: red;
  display: flex;

  span { padding: 0.5rem 1.5rem; }
  span:empty { padding: 0; }
}

@media only screen and (min-width: 870px) {
  .dashboard {
    padding-left: 2rem;
  }
}

@page {
  margin: 0;
}

@media print {
  body { zoom: 82%; }
  .dashboard-title { display: none; }
  .agent { display: none; }
}
